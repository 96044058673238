$largeScreen: 900px;
$smallScreen: 500px;

@import "include/fonts";

@import "include/reset";
@import "mixins/noselect";
@import "mixins/rounded";

*, *:after, *:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
}

* {
	padding: 0;
	margin: 0;
}

*:focus {
	outline: 0;
}

body {
	font-family: $text;
}

.fa-tableau {
	font-family: 'icomoon';
}

.fa-tableau::before {
	content: "\e900";
}

.customScrollBar {
	::-webkit-scrollbar {
		position: absolute;
		width: 6px;
		height: 6px;
	}

	::-webkit-scrollbar-thumb {
		background: #666;
		border-radius: 20px;
	}

	::-webkit-scrollbar-track {
		background: #ddd;
		border-radius: 20px;
	}

	::-webkit-scrollbar-corner {
		height: 6px;
		width: 6px;
		background: #ddd;
	}
}

main {
	padding: 10px 10px 10px 10px;
	min-height: 100vh;
}

ul {
	list-style:none;
}

.link {
	cursor:pointer;
}

.inline-file {
	margin-bottom: 10px;
}

.hidden {
	display: none;
}

#loader {
	flex-direction: column;
	z-index: 9999;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;

	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;

	backdrop-filter: blur(1px);
	-webkit-backdrop-filter: blur(1px);

	-webkit-transition: 0.4s;
	-moz-transition: 0.4s;
	transition: 0.4s;
	opacity: 1;

	i {
		font-size: 4em;
	}

	#loader-status {
		padding-top: 10px;
		display: none;
	}
}

#debug {
	font-size: 12px;
	margin: 5px;
	display: inline-block;
	border: 1px solid #681d1d;
	background-color: #aa3838;
	color: #fff;
	border-radius:5px;
	li {
		padding:5px 10px;
		border-bottom: 1px solid #681d1d;
		&:last-child {
			border:none;
		}
	}
}

.highcharts-container  {
	.highcharts-credits {
		display: none;
	}
}

@media only screen and (max-width: $largeScreen) {
	.hiddenOnSmallScreen {
		display: none;
	}
}

@media only screen and (max-width: $smallScreen) {
	.swal2-header {
		font-size: 0.7em;
	}
}
@import "include/swal2";
@import "include/network";
@import "include/pathbar";
@import "include/stickyheader";
@import "include/dropdown";
@import "include/tree";
@import "include/form";
@import "include/card";
@import "include/button";
@import "include/panel";
@import "include/upload";
@import "include/userSelector";
@import "include/elementSelector";
@import "include/inlineFiles";
@import "include/analyser";
@import "include/highlightscheme";
@import "include/playlist";
@import "include/questionnaire";
@import "include/questionnaireReports";
@import "include/portfolio";
@import "include/tags";
@import "include/files";
@import "include/home";
@import "include/table";
@import "include/calendar";
@import "include/comments";
@import "include/vtt";
@import "include/admin";
@import "include/badges";
@import "include/help";
@import "include/tableau";
@import "include/shortlists";
@import "include/tacticalField";
